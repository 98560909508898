/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
type RequestMethod = 'GET' | 'GET_WITHOUT_AUTH' | 'POST' | 'POST_WITHOUT_AUTH' | 'PUT' | 'PATCH' | 'DELETE';
import { LocalStorageEnum } from '../constants/enum';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import SessionExpiredError from '../errors/SessionExpiredError';
import { useRouter } from '@tanstack/react-router';


export interface IHttpResponse {
  data: any;
}

function verifySessionToken() {
  const authStorage: string = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE) ?? '';
  const { state } = JSON.parse(authStorage);
  const { authToken } = state;
  const jwtInfo = jwtDecode<JwtPayload>(authToken);
  const currentTime = Date.now() / 1000;
  if (jwtInfo.exp && jwtInfo.exp < currentTime) {
    return true;
  }
  return false;
}

export default function useAxios() {
  const router = useRouter();
  const response: IHttpResponse = {
    data: undefined,
  };
  const baseURL: string = import.meta.env.VITE_NIGHT_FALL_HOST;
  const authStorage: string = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE) ?? '';
  const { state } = JSON.parse(authStorage);
  const { authToken: token } = state;
  const axiosInstance = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: token ? `Bearer ${String(token)}` : '',
    },
  });

  axiosInstance.interceptors.request.use(
    async (req: any) => {
      const isExpired = verifySessionToken();
      if (!isExpired) {
        const authStorage: string = localStorage.getItem(LocalStorageEnum.AUTH_STORAGE) ?? '';
        const { state } = JSON.parse(authStorage);
        const { authToken } = state;
        req.headers.Authorization = token ? `Bearer ${String(authToken)}` : '';
        return req;
      } else {
        throw new SessionExpiredError('Session Expired');
        // should implement refetch token
      }
    },
    function (error) {
      return Promise.reject(error);
    },
    
  );
  axiosInstance.interceptors.response.use(
    function (response) {
      // Return the response data if no error occurs
      return response;
    },
    function (error: AxiosError) {
      // Check if the error response status is 401 (Unauthorized)
      if (error.response?.status === 401) {
        // Clear localStorage (or sessionStorage) if the token has expired or is invalid
        localStorage.removeItem(LocalStorageEnum.AUTH_STORAGE);
        sessionStorage.clear(); // or use localStorage.clear() depending on where your session info is stored
        router.navigate({
          to: '/sessionNotFound',
          replace: true, // Optional: Replaces current entry in history
        });
        throw new SessionExpiredError('Session Expired. Please log in again.');
        
      }

      return Promise.reject(error);
    },
  );

  async function request(method: RequestMethod, endpoint = '', body = {}): Promise<any> {
    const url = `${baseURL}${endpoint}`;

    const reqBody = method !== 'GET' && method !== 'GET_WITHOUT_AUTH' && JSON.stringify(body);
    if (method === 'GET') {
      const { data } = await axiosInstance.get(url, { params: body });
      response.data = data;
    } else if (method === 'GET_WITHOUT_AUTH') {
      const { data } = await axios.get(url, { params: body });
      response.data = data;
    } else if (method === 'DELETE') {
      const { data } = await axiosInstance.delete(url);
      response.data = data;
    } else if (method === 'POST') {
      const { data } = await axiosInstance.post(url, reqBody);
      response.data = data;
    } else if (method === 'POST_WITHOUT_AUTH') {
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: '',
        },
      };
      const { data } = await axios.post(url, reqBody, config);
      response.data = data;
    } else if (method === 'PUT') {
      const { data } = await axiosInstance.put(url, reqBody);
      response.data = data;
    } else if (method === 'PATCH') {
      const { data } = await axiosInstance.patch(url, reqBody);
      response.data = data;
    }
    return response.data;
  }

  async function getWithAuth(url: string, data?: any): Promise<any> {
    return await request('GET', url, data);
  }

  async function getWithoutAuth(url: string, data?: any): Promise<any> {
    return await request('GET_WITHOUT_AUTH', url, data);
  }
  async function postWithAuth(url: string, data: any): Promise<any> {
    return await request('POST', url, data);
  }
  async function postWithoutAuth(url: string, data?: any): Promise<any> {
    return await request('POST_WITHOUT_AUTH', url, data);
  }
  async function putWithAuth(url: string, data: any): Promise<any> {
    return await request('PUT', url, data);
  }
  async function patchWithAuth(url: string, data: any): Promise<any> {
    return await request('PATCH', url, data);
  }
  async function deleteWithAuth(url: string): Promise<any> {
    return await request('DELETE', url);
  }

  return { getWithAuth, getWithoutAuth, postWithAuth, postWithoutAuth, putWithAuth, patchWithAuth, deleteWithAuth };
}
