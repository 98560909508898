import { Link, createFileRoute, joinPaths, redirect } from '@tanstack/react-router';
import React, { useEffect, useRef, useState } from 'react';
import StaticSearch from '../components/StaticSearch';
import Dish from '../components/Dish';
import { AnimatePresence, motion } from 'framer-motion';
import { useAuthStore } from '../store/authStore';
import { capitalizeFirstLetter, isAuthenticated, parseAxiosError } from '../utils/common';
import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { ReactQueryEnum, SessionStatus } from '../constants/enum';
import useAxios from '../hooks/useAxios';
import {
  AllRestaurantInterface,
  DishInterface,
  FoodType,
  RestaurantCategoryInterface,
  DiningSession,
} from '../Interface';
import Switch from '../components/Switch';
import { useCartStore } from '../store/cartStore';
import { GrNext } from 'react-icons/gr';
import { FaCaretDown } from 'react-icons/fa';
import { Button, Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import clsx from 'clsx';
import ShowToast, { ToastContainerWrapper } from '../components/Toast';
import useDiningSessionStore from '../store/useDiningSessionStore';
import useDebounce from '../hooks/useDebounce';
import SkeletonDish from '../components/Skeleton/SkeletonDish';
import SkeletonCategory from '../components/Skeleton/SkeletonCategory';
import { AxiosError } from 'axios';
import SessionExpiredError from '../errors/SessionExpiredError';
import Share from '../components/Share';
import { GoShareAndroid } from 'react-icons/go';
import Loading from '../components/Loading';
import { PiCallBellLight } from 'react-icons/pi';
import CustomError from '../components/Error';

function UserDropDownMenu({ users }: { users: Array<{ name: string; socketId: string }> }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-full bg-transparent px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm">
          {({ active }: { active: boolean }) => (
            <FaCaretDown
              className={clsx('-mr-1 h-5 w-5 transition-transform duration-200 text-white opacity-70', {
                'rotate-180': active,
              })}
              aria-hidden="true"
            />
          )}
        </MenuButton>
      </div>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-10 mt-1 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {users.length > 0 &&
              users.map((user, index) => {
                return (
                  <MenuItem key={index}>
                    {({ focus }) => (
                      <a
                        href="#"
                        className={clsx('block px-4 py-2 text-sm', {
                          'bg-gray-100 text-gray-900': focus,
                          'text-gray-700': !focus,
                        })}
                      >
                        {user.name}
                      </a>
                    )}
                  </MenuItem>
                );
              })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}

export const Route = createFileRoute('/order')({
  beforeLoad: () => {
    if (!isAuthenticated()) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  component: Order,
});

function Order() {
  const [shareOpen, setShareOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce<string>(search, 400);
  const [order, setOrder] = useState([]);

  const { cart, users, changeNavbarVisibility,cartLoading } = useCartStore(
    (state) => ({
      cart: state.cart,
      users: state.users,
      changeNavbarVisibility: state.changeNavbarVisibility,
      cartLoading: state.cartLoading,
    }),
  );
  const [totalItems, setTotalItems] = useState<number>(0);
  const [isVegOnly, setIsVegOnly] = useState<boolean>(false);
  const { authToken, tablePIN, sessionID, userName, clearTokens, isAuthenticated } = useAuthStore((state) => ({
    authToken: state.authToken,
    tablePIN: state.tablePIN,
    sessionID: state.sessionID,
    userName: state.userName,
    clearTokens: state.clearTokens,
    isAuthenticated: state.isAuthenticated,
  }));
  const { getWithAuth, getWithoutAuth, postWithAuth } = useAxios();
  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});


  const { isPending: helpPending, mutate: helpMutate } = useMutation({
    mutationFn: async () => {
      const response = await postWithAuth('/app/dining-session/help', {});
      return response;
    },
    onSuccess: () => {
      ShowToast({ message: 'Called for assistance!', type: 'success' });
    },
    onError: (error: AxiosError) => {
      const { errorMessage } = parseAxiosError(error);
      ShowToast({ message: errorMessage, type: 'error' });
    },
  });

  const handleScrollToCategory = (categoryId: string) => {
    if (categoryRefs.current[categoryId]) {
      categoryRefs.current[categoryId]!.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const {
    data: sessionData,
    isError: isSessionError,
    isLoading: isSessionLoading,
    failureReason,
    error,
  } = useQuery<DiningSession>({
    queryKey: [ReactQueryEnum.SESSION, sessionID],
    queryFn: async () => {
      const response = await getWithAuth(`/app/dining-session/session`);
      return response;
    },
    retry(failureCount, error) {
      if ((error as AxiosError).response?.status === 404) {
        return false;
      }
      if (error instanceof SessionExpiredError) {
        return false;
      }
      return failureCount < 3;
    },
    refetchInterval: 10000,
  });

  const shareRef = useRef<{ title: string; url: string }>({
    title: 'Hello there, Get on the table and place your order by clicking the link!',
    url:
      sessionData && sessionData.tableId
        ? `${import.meta.env.VITE_EMPRESS_HOST}/register/${sessionData.tableId}?pin=${tablePIN}`
        : '',
  });
  const menuResult = useQuery({
    queryKey: [ReactQueryEnum.MENU, sessionData?.restaurantId, debouncedSearch],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/dish/menu/${sessionData?.restaurantId}?query=${debouncedSearch}`);
      return response;
    },
    enabled: !!sessionData?.restaurantId,
  });

  const {
    data: restaurantData,
    isError: isRestaurantError,
    isLoading: isRestaurantLoading,
  }: UseQueryResult<AllRestaurantInterface, Error> = useQuery({
    queryKey: [ReactQueryEnum.RESTAURANT, sessionData?.restaurantId],
    queryFn: async () => {
      const response = await getWithoutAuth(`/app/restaurant/${sessionData?.restaurantId}`);
      return response;
    },
    enabled: !!sessionData?.restaurantId,
  });

  const profileLength = Math.min(4, users.length);

  useEffect(() => {
    if (sessionData) {
      shareRef.current.url = `${import.meta.env.VITE_EMPRESS_HOST}/register/${sessionData.tableId}?pin=${tablePIN}`;
    }
  }, [sessionData]);
  useEffect(() => {
    changeNavbarVisibility(false);
  }, []);
  useEffect(() => {
    setTotalItems(cart.reduce((acc, item) => acc + item.quantity, 0));
  }, [cart]);

  if (isSessionError) {
    const error = failureReason as AxiosError;
    const { errorMessage } = parseAxiosError(error);
    if (error instanceof SessionExpiredError) {
      clearTokens();
      return (
        <CustomError>
          <p>Your session has ended!</p>
          <Link to="/" className="text-primary underline">
            Go to Home
          </Link>
        </CustomError>
      );
    } else {
      return (
        <CustomError>
          <p className="font-medium ">{errorMessage}</p>
          <p className="text-sm font-medium">Please try again later</p>
        </CustomError>
      );
    }
  }

  if (isRestaurantLoading || isSessionLoading) {
    return <Loading />;
  }

  if (!restaurantData || !sessionID || !sessionData || menuResult.isError || isRestaurantError) {
    return;
  }
  if (sessionData && sessionData.status === SessionStatus.CHECKOUT) {
    return (
      <CustomError>
        {/* <p className="font-medium ">Thank you for using Cibo!</p> */}
        <p className=" font-medium text-center">Your session at {sessionData.restaurantName} has ended!</p>
        <Link to="/" className="text-primary underline">
          Proceed to Home
        </Link>
        {/* <Link to="/bill/$billId" params={{
          billId: sessionData.captainId
        }} className="text-primary underline">
          View Bill
        </Link> */}
      </CustomError>
    );
  }

  if (sessionData && sessionData.status === SessionStatus.CHECKOUT) {
    return <p>Checkout in progress</p>;
  }

  const itemVariants = {
    hidden: { opacity: 0, y: 100, transition: { type: 'tween', ease: 'easeOut', duration: 0.3 } },
    visible: { opacity: 1, y: 0, transition: { type: 'tween', ease: 'easeOut', duration: 0.3 } },
    exit: { opacity: 0, y: 100, transition: { type: 'tween', ease: 'easeIn', duration: 0.3 } },
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share(shareRef.current);
      } catch (error) {
        return;
      }
    } else {
      setShareOpen(true);
    }
  };
  return (
    <div className={clsx("",{
      "pointer-events-none opacity-50":cartLoading
    })}>
      <ToastContainerWrapper />
      {sessionData?.tableId && (
        <Share url={shareRef.current.url} title={shareRef.current.title} isOpen={shareOpen} setIsOpen={setShareOpen} />
      )}
      <AnimatePresence mode="wait">
        {(totalItems > 0 ||
          (sessionData && (sessionData.confirmedOrders.length > 0 || sessionData.pendingOrders.length > 0))) && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={itemVariants}
            className={clsx(
              'fixed bottom-0 w-full left-0 bg-white shadow-addItem rounded-t-2xl text-lg p-4 flex flex-row  items-center gap-3',
              {
                'justify-between': totalItems > 0,
                'justify-end': totalItems === 0,
              },
            )}
          >
            {totalItems > 0 && (
              <p className="whitespace-nowrap overflow-ellipsis overflow-hidden">{`${totalItems} ${totalItems <= 1 ? 'Item' : 'Items'} added`}</p>
            )}
            <Link to="/orderReview" className="flex flex-row justify-center items-center gap-1">
              <p className="whitespace-nowrap">{totalItems === 0 ? 'Review Order' : 'View Cart'}</p>
              <GrNext className="text-sm font-medium mt-0.5" />
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="bg-gradient-to-b to-primary/95 from-gradient_black h-fit rounded-b-2xl p-4  text-white mb-2 w-full overflow-hidden ">
        <div className="flex flex-col gap-2 justify-center items-center">
          {/* <img src={restaurantData.logoUrl} alt="" className="rounded-full  object-cover h-14 w-14" /> */}
          <p className="text-lg font-medium">{capitalizeFirstLetter(restaurantData.name)}</p>
        </div>
        <div className="flex justify-between items-center mb-2  w-full gap-2">
          <p className="whitespace-nowrap overflow-ellipsis overflow-hidden">
            <span className="opacity-70">Hello,</span>
            <br />
            <span className="  ">{userName && capitalizeFirstLetter(userName)}</span>
          </p>
          <div className="flex flex-col justify-center items-center select-none">
            <p className="opacity-70">Table Pin</p>
            <div
              onClick={handleShare}
              className="rounded-lg bg-white bg-opacity-15 px-2 py-0.5 flex justify-center items-center"
            >
              <p className=" font-semibold   tracking-[0.5em] ">{tablePIN}</p>
              <GoShareAndroid />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-4  w-full gap-2">
          {/* <p className="  overflow-ellipsis overflow-hidden break-all max-w-[60%]">{restaurantData.name}</p> */}

          <motion.button
            onClick={() => {
              helpMutate();
            }}
            whileTap={{ scale: 0.97 }}
            className="px-2 p-1 rounded-md border flex gap-2 justify-center items-center"
            disabled={helpPending}
          >
            <p className="text-sm">Call for help</p>
            <PiCallBellLight className="h-5 w-5" />
          </motion.button>
          <div className="flex gap-0.5 justify-center items-center">
            <div
              className={clsx('relative flex h-7 w-7', {
                'right-6': users.length > 4,
                'right-3': users.length <= 4,
              })}
            >
              {users.length > 0 &&
                users.slice(0, 4).map((user, index) => {
                  return (
                    <img
                      key={index}
                      src={`/images/avatars/avatar_${index}.svg`}
                      style={{
                        left: `-${(profileLength - index) * 1}rem`,
                      }}
                      className="w-7 h-7 absolute"
                      alt=""
                    />
                  );
                })}
              {users.length > 4 && (
                <div className="rounded-full w-7 h-7 flex justify-center items-center  absolute bg-[#9F82BB] text-white">
                  <p className="h-5 leading-[19px] text-sm font-semibold text-black ">{`+${users.length - 4}`} </p>
                </div>
              )}
            </div>
            <div className="absolute">
              <UserDropDownMenu users={users} />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="px-4 w-full pt-3">
          <StaticSearch key="search" search={search} setSearch={setSearch} />
        </div>
        <div className=" sticky top-0 flex flex-col w-full justify-between items-center gap-3 py-3 bg-white">
          <div className="px-4 flex gap-2 overflow-x-scroll w-full no-scrollbar justify-start items-center">
            {!menuResult.isLoading && (
              <div className="flex gap-2 justify-center items-center justify-self-start border px-2 py-1 rounded-lg">
                <p className="whitespace-nowrap">Veg Only</p>
                <Switch isOn={isVegOnly} onClick={() => setIsVegOnly(!isVegOnly)} />
              </div>
            )}
            {menuResult.isLoading
              ? Array.from({ length: 5 }).map((_, index) => <SkeletonCategory key={index} />)
              : menuResult.data !== undefined &&
                menuResult.data.map((menu: RestaurantCategoryInterface, index: number) => {
                  return (
                    <motion.div
                      key={index}
                      whileTap={{ scale: 0.97 }}
                      className="border px-2 py-1 rounded-lg"
                      onClick={() => handleScrollToCategory(menu.restaurantCategoryId)}
                    >
                      <p className="whitespace-nowrap">{menu.restaurantCategoryName}</p>
                    </motion.div>
                  );
                })}
          </div>
        </div>

        <div className="flex flex-col gap-5 mb-3 w-full mt-2 px-4 pb-16">
          {menuResult.isLoading
            ? Array.from({ length: 5 }).map((_, index) => <SkeletonDish key={index} />)
            : menuResult.data !== undefined &&
              menuResult.data.map((menu: RestaurantCategoryInterface) => (
                <div
                  className=""
                  key={menu.restaurantCategoryId}
                  id={menu.restaurantCategoryId}
                  ref={(el) => (categoryRefs.current[menu.restaurantCategoryId] = el)}
                >
                  <p className="text-left text-base font-semibold mb-2">
                    {capitalizeFirstLetter(menu.restaurantCategoryName)}
                  </p>
                  <div className="grid grid-cols-1 gap-2">
                    {menu.dishes.map((dish: DishInterface) => {
                      if (isVegOnly && dish.dishType === FoodType.VEG) {
                        return <Dish dish={dish} key={dish._id} tableId = {""} isAuthenticated={isAuthenticated()}/>;
                      } else if (!isVegOnly) {
                        return <Dish dish={dish} key={dish._id} tableId = {""} isAuthenticated={isAuthenticated()}/>;
                      }
                    })}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default Order;
