import clsx from 'clsx';
import { CartInterface } from '../Interface';
import { getCustomisation } from '../utils/common';
import { ItemCounter } from './Dish';
import { useCartStore } from '../store/cartStore';

const OrderReviewDish = ({ item, index, cartLength }: { item: CartInterface; index: number; cartLength: number }) => {
  const { updateCart } = useCartStore();

  const addToCartHandler = () => {
    console.log(item.dishId)
    updateCart([{ uuid: item.uuid, quantity: 1 }]);
  };

  const removeFromCartHandler = () => {
    if (item.quantity > 0) {
      updateCart([{ uuid: item.uuid, quantity: - 1 }]);
    }
  };

  return (
    <div
      key={index}
      className={clsx('grid grid-cols-[3fr_1.5fr_1fr] gap-2 pb-3 mr-2', {
        'border-b mb-3': index !== cartLength - 1,
      })}
    >
      <div className="flex flex-col justify-center items-start">
        <p className="text-xs text-gold_start">{item.userName}</p>
        <p className="font-medium">{item.dishName}</p>
        {item.customisations && <p className="text-xs text-grey_text">{getCustomisation(item.customisations)}</p>}
      </div>
      <div className="flex justify-center items-center">
        <ItemCounter
          removeItem={removeFromCartHandler}
          count={item.quantity}
          addItem={addToCartHandler}
        />
      </div>
      <div className="flex justify-center items-center">&#8377;{item.price}</div>
    </div>
  );
};

export default OrderReviewDish;
